import React from "react";
import LogoWithText from '../../media/icons/LogoWithText.png';

import css from "./Footer.module.scss";
// import Logo from "../Logo";
import Container from "../Container";
import Navigation from "../Navigation";
import SocialLink from "../SocialLink";
import { useTranslation } from 'react-i18next';

const Footer = ({ data }) => {
  // console.log(data);
  const { t, i18n } = useTranslation();
  return (


<>

<div className={css.wrapperBoxBackground}>

  

    <Container>
      <div className={css.footerWrapper}>
        <div className={css.logoWrapper}>
          {/* <Logo className={css.logo} /> */}
          <img className={css.logo} src={LogoWithText} alt="123" />

          <p className={css.description}>{t(data.title_3)}</p>
        </div>

        <div className={css.navWrapper}>
          <Navigation showStyle="column" background={true}/>
        </div>

        <div className={css.contactsWrapper}>
          <h3 className={css.contactsTitle}>{t(data.title_2)}</h3>
          <p className={css.contactsLocation}>{t(data.location.text)}</p>
          <p className={css.contactsPhone_1}>{data.phone[1]}</p>
          <p className={css.contactsPhone_2}>{data.phone[2]}</p>
          <p className={css.contactsEmail}>{data.email}</p>
        </div>
      </div>

      <hr></hr>

      <div className={css.socialWrapper}>
        <p className={css.titleCopyright}>{t(data.title_4)}</p>
        <SocialLink objData={data.link} title={data.title_5} />
      </div>

    </Container>
</div>


</>




  );
};

export default Footer;
