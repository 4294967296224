import React, { useEffect } from "react";

import SimpleSlider from "../../components/SimpleSlider";
import allData from "./allData/allData";
import AboutUs from "../../components/AboutUs";
import History from "../../components/History";
import Product from "../../components/Product";
import MapsWorld from "../../components/MapsWorld";
import OwnRetail from "../../components/OwnRetail/OwnRetail";
import Office from "../../components/Office";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";
import SimpleSlider_2 from "../../components/SimpleSlider_2";

import { useTranslation } from "react-i18next";

import css from "./Home.module.scss";

const {
  dataSlider_1,
  dataAboutUs,
  dataHistory,
  dataProduct,
  dataWorldMap,
  dataProduction,
  dataSlider_2,
  dataOwnRetail,
  dataOffice,
  dataContacts,
} = allData;

const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section>
        <SimpleSlider dataSlider={dataSlider_1} />
      </section>
      <section id="about">
        <AboutUs dataAboutUs={dataAboutUs} />
      </section>
      <section>
        <History dataHistory={dataHistory} />
      </section>
      <section id="products">
        <Product data={dataProduct} />
      </section>
      <section id="import">
        <MapsWorld data={dataWorldMap} />
      </section>
      <section id="production">
        <Product data={dataProduction} />
      </section>
      <section>
        <SimpleSlider_2 dataSlider={dataSlider_2} />
      </section>
      <section id="retail">
        <OwnRetail data={dataOwnRetail} />
      </section>
      <section>
        <Office data={dataOffice} />
      </section>
      <section>
        <GoogleMaps data={dataContacts} />
      </section>
    </>
  );
};

export default Home;
