import React, { useState } from "react";

import css from "./MapsWorld.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from 'react-i18next';

const MapsWorld = ({ data }) => {
  // console.log(data);
  const { t, i18n } = useTranslation();



  return (
    <div className={css.MapsWorldWrapper}>
      <Container>
        <Title title={t(data.title)} color='white'/>
        <p className={css.countries}>{t(data.description)}</p>
        <img src={data.imgUrl} alt='Карта' />

    
       
      </Container>
    </div>
  );
};

export default MapsWorld;
