import React from "react";
import css from "./CardHistory.module.scss";

import { useTranslation } from "react-i18next";

const CardHistory = ({ objData, index }) => {
  // console.log(objData);
  const { t, i18n } = useTranslation();

  return (
    <div className={css.cardWrapper}>
      {index % 2 ? (
        <>
          <div className={css.cardInner_1}>
            <div className={css.positionWrapper_1}>
              <div className={css.yearWrapper_1}>

                <div className={css.year}>
                  {objData.year}
 
                </div>

                <div
                  className={objData.id !== 8 ? css.dot_line : css.dot}
                ></div>

              </div>
            </div>

            <div className={css.decorWrapper_1}>
              <p className={css.title}>{t(objData.title)}</p>
              <p className={css.description}>{t(objData.description)}</p>
            </div>


          </div>
        </>
      ) : (
        <>
          <div className={css.cardInner_2}>
            <div className={css.decorWrapper_2}>
              <p className={css.description}>{t(objData.description)}</p>
              <p className={css.title}>{t(objData.title)}</p>
            </div>

            <div className={css.positionWrapper_2}>
              <div className={css.yearWrapper_2}>
                <div className={css.dot}></div>

                <div className={css.year}>
    
                  {objData.year}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CardHistory;
