// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialLink_socialLinkWrapper__Q2Y6h {
  display: flex;
  align-items: center;
}
.SocialLink_socialLinkWrapper__Q2Y6h .SocialLink_title__4D\\+iU {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 24px;
  color: var(--text-color-white);
}
.SocialLink_socialLinkWrapper__Q2Y6h .SocialLink_linkWrapper__kQ61f {
  display: flex;
  gap: 15px;
}
.SocialLink_socialLinkWrapper__Q2Y6h .SocialLink_linkWrapper__kQ61f .SocialLink_linkButton__qQwna {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 769px) {
  .SocialLink_socialLinkWrapper__Q2Y6h {
    margin-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SocialLink/SocialLink.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACE,mBAAA;AAAJ;AAEA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,8BAAA;AADA;AAMA;EACE,aAAA;EAEA,SAAA;AALF;AAUE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EAEA,oCAAA;AATJ;AAkBE;EAvCF;IAwCI,mBAAA;EAfF;AACF","sourcesContent":[".socialLinkWrapper {\n  // border: 1px solid tomato;\n  display: flex;\n    align-items: center;\n\n.title {\n\nfont-size: 24px;\nfont-weight: 500;\nline-height: 24px;\nmargin-right: 24px;\ncolor: var(--text-color-white);\n\n\n}\n\n.linkWrapper {\n  display: flex;\n\n  gap: 15px;\n\n\n\n\n  .linkButton {\n    width: 39px;\n    height: 39px; \n    border-radius: 50%;\n    border: none;\n    // background-color: rgba(56, 194, 199, 0.5);\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n}\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialLinkWrapper": `SocialLink_socialLinkWrapper__Q2Y6h`,
	"title": `SocialLink_title__4D+iU`,
	"linkWrapper": `SocialLink_linkWrapper__kQ61f`,
	"linkButton": `SocialLink_linkButton__qQwna`
};
export default ___CSS_LOADER_EXPORT___;
