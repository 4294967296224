import React, { useEffect, useState } from "react";
import css from "./DropdownMenu.module.scss";
import Navigation from "../Navigation";
import { IoIosArrowDown } from "react-icons/io";

const DropdownMenu = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  //   useEffect(() => {
  // console.log(isMenuVisible);
  //   },[isMenuVisible])

  const handleMouseEnter = () => {
    setMenuVisible(true);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  return (
    <div
      className={css.dropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className={css.dropdown_button}>
        <p>МЕНЮ</p>
        <IoIosArrowDown className={css.custom_icon} />
      </button>
      
      {isMenuVisible && (
        <div className={css.dropdown_content}>
          <Navigation />
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
