// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OwnRetail_ownRetailWrapper__HCaF\\+ {
  background: var(--page-color-gray);
  padding: 80px 0 80px 0;
}
.OwnRetail_ownRetailWrapper__HCaF\\+ .OwnRetail_ownRetailList__qLM5u {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 769px) {
  .OwnRetail_ownRetailWrapper__HCaF\\+ .OwnRetail_ownRetailList__qLM5u {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
}
@media (max-width: 769px) {
  .OwnRetail_ownRetailWrapper__HCaF\\+ {
    padding: 45px 0 45px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/OwnRetail/OwnRetail.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EAEA,sBAAA;AAAF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AAAJ;AAEI;EALF;IAMI,sBAAA;IACA,uBAAA;IACA,mBAAA;IACA,SAAA;EACJ;AACF;AAOE;EAvBF;IAwBI,sBAAA;EAJF;AACF","sourcesContent":[".ownRetailWrapper {\n  background: var(--page-color-gray);\n  // border: 1px solid tomato;\n  padding: 80px 0 80px 0;\n\n  .ownRetailList {\n    display: flex;\n    justify-content: space-between;\n    gap: 20px;\n\n    @media (max-width: 769px) {\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      gap: 32px;\n    }\n\n  }\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    padding: 45px 0 45px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ownRetailWrapper": `OwnRetail_ownRetailWrapper__HCaF+`,
	"ownRetailList": `OwnRetail_ownRetailList__qLM5u`
};
export default ___CSS_LOADER_EXPORT___;
