import React from "react";
import { FiPhone } from "react-icons/fi";  // Імпортуємо іконку
import css from './Contacts.module.scss';

const Contacts = ({ icon, text }) => {
  return (
    <div className={css.wrapper}>
      <div className={css.iconWrapper}>
        {icon} 
      </div>
      <p className={css.text}>{text}</p>
    </div>
  );
};

export default Contacts;
