// Logo.jsx
import React from "react";
import { ReactComponent as LogoSvg } from './../../media/icons/Logo.svg';
import css from './Logo.module.scss'

const Logo = () => {
  return (
    <div className={css.wrapper}>
      <LogoSvg className={css.icon}/>
      <h2 className={css.text}>KRAMAR LTD</h2>
    </div>
  );
};

export default Logo;
