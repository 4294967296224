import React from "react";
import css from "./OwnRetailCard.module.scss";
import { useTranslation } from "react-i18next";

const OwnRetailCard = ({ objData }) => {
  // console.log("дані для картки 1", objData);
  const { t, i18n } = useTranslation();

  return (
    <div className={css.ownRetailCardWrapper}>
      <img className={css.ownRetailImg} src={objData.imgUrl} alt={objData.id} />
      <h3 className={css.ownRetailTitle}>{t(objData.title)}</h3>

      <p className={css.ownRetailDescription}>{t(objData.description)}</p>

      <button
        className={css.ownRetailButton}
        onClick={() =>
          window.open(objData.siteURL, "_blank", "noopener,noreferrer")
        }
      >
        {t("dataOwnRetail.nameButton")}
      </button>
    </div>
  );
};

export default OwnRetailCard;
