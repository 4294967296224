import React, { useEffect, useState } from "react";

import css from "./Office.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from 'react-i18next';
import { tab } from "@testing-library/user-event/dist/tab";


const Office = ({ data }) => {
  const { t, i18n } = useTranslation();
  // console.log(data);

  const [services, setServices] = useState(true);

  const showServices = () => {
    setServices(!services);
  };

  return (
    <div className={css.officeWrapper}>
      <Container>
        <Title title={t(data.title)} />
        <p className={css.description}>{t(data.description)}</p>

        {services ? (
          <ul className={css.list}>
            {data.arrServices.map((el, index) => (
              <li className={css.item} key={index}>
                <a className={css.link} href="/">{t(el.title)}
                
                <img src={el.imgUrl} alt="index" />
                </a>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </Container>

      <div className={css.buttonWrapper}>
        

      <button className={css.sectionButton}>
  <a href="https://cabinet.td-kramar.com/" target="_blank" rel="noopener noreferrer">
    {t(data.titleButton)}
  </a>
</button>
      </div>

    </div>
  );
};

export default Office;
