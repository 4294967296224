// LanguageSwitcher.jsx
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'; // Додано імпорт
import css from './LanguageSwitcher.module.scss'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); 




  useEffect(()=> {
    localStorage.setItem('i18nextLng', 'ua');
  },[localStorage.i18nextLng])
  

  const [activeLanguage, setActiveLanguage] = useState("UA");





  const switchLanguage = (language) => {
    setActiveLanguage(language);
    i18n.changeLanguage(language.toLowerCase()); // Зміна мови через i18n
  };



  return (
    <div className={css.wrapperLanguage}>
      <button
        className={`${css.langButton} ${activeLanguage === 'UA' ? css.activeLang : ''}`}
        onClick={() => switchLanguage("UA")}
      >
        UA
      </button>
      <button
        className={`${css.langButton} ${activeLanguage === 'EN' ? css.activeLang : ''}`}
        onClick={() => switchLanguage("EN")}
      >
        EN
      </button>
    </div>
  );
};

export default LanguageSwitcher;
