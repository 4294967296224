import React from "react";

import css from "./AboutUs.module.scss";
import Container from "../Container";
import Title from "../Title/Title";

import { useTranslation } from 'react-i18next';

const AboutUs = ({ dataAboutUs }) => {
  // console.log(dataAboutUs);
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <div className={css.aboutUsWrapper}>
        {/* <Title title={dataAboutUs.title} color={'white'}/>   */}
        <Title title={t(dataAboutUs.title)} color={'white'}/>  

        <div className={css.textWrapper}>
          {dataAboutUs.text.map((el, index) => (
            <p key={index} className={css.text}>{t(el)}</p>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
