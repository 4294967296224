import React, { useState, useEffect } from "react";
import Logo from "../Logo";
import css from "./Header.module.scss";
import Navigation from "../Navigation";
import Contacts from "../Contacts";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { LuMapPin } from "react-icons/lu";
import LanguageSwitcher from "../LanguageSwitcher";
import Container from "../Container";
import DropdownMenu from "../DropdownMenu";
import allData from "../../pages/Home/allData";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [showBurger, setShowBurger] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleBurger = () => {
    setShowBurger(!showBurger);
  };

  // useEffect(() => {
  //   console.log(showBurger);
  // }, [showBurger]);

  const { dataContacts } = allData

  return (
    <div className={css.wrapperPosition}>
      <Container>
        <header className={css.wrapper}>

              <div className={css.wrapperDecorContacts}>


              <Contacts className={css.locationText} icon={<LuMapPin />} text={t(dataContacts.location.text)} />


              <div className={css.wrapperBoxText}>

              <Contacts className={css.locationText} icon={<FiPhone />} text="+38 (067) 674 74 24" />
              <p className={css.decorLine}>I</p>
              <Contacts className={css.locationText}
                icon={<MdOutlineMailOutline />}
                text="office@kramar-ltd.com.ua"
                />
                </div>


            </div>


<div className={css.wrapperBox}>



          <div className={css.wrapperLogo}>
            <Logo />
          </div>

          <div className={css.wrapperShow}>
            <div className={css.wrapperNavLine}>
              <Navigation showStyle="line" />
            </div>

            <div className={css.nawDropdown}>
              <DropdownMenu />
            </div>

            {/* <div className={css.wrapperDecorContacts}>
              <Contacts icon={<FiPhone />} text="123-456-7890" />
              <p className={css.decorLine}>I</p>
              <Contacts
                icon={<MdOutlineMailOutline />}
                text="office@kramar-ltd.com.ua"
              />
            </div> */}


            <div className={css.wrapperSwitcher}>
              <LanguageSwitcher />
            </div>
          </div>

          <button className={css.burgerButton} onClick={toggleBurger}>
            {!showBurger ? (
              <GiHamburgerMenu className={css.openIcon} />
            ) : (
              <MdClose className={css.closeIcon} />
            )}
          </button>
          </div>
        </header>
      </Container>

      {showBurger && (
        <div className={css.burgerContent}>
          <Container>
            <div className={css.wrapperNavBurger}>
              <Navigation />
            </div>

            <div className={css.wrapperDecorContactsBurger}>
              <Contacts icon={<FiPhone />} text="123-456-7890" />
              {/* <p className={css.decorLine}>I</p> */}
              <Contacts
                icon={<MdOutlineMailOutline />}
                text="office@kramar-ltd.com.ua"
              />
            </div>

            <div className={css.wrapperSwitcherBurger}>
              <LanguageSwitcher />
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Header;
