import React from "react";
import css from './Navigation.module.scss';
import { useTranslation } from 'react-i18next';

const Navigation = ({ showStyle, background }) => {
  const { t, i18n } = useTranslation();
  return (
    <nav className={showStyle === 'line' ? css.wrapperNavLine : css.wrapperNavColumn}>
    <ul className={css.list}>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#about">{t('dataHeader.obj_1.title')}</a></li>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#products">{t('dataHeader.obj_2.title')}</a></li>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#import">{t('dataHeader.obj_3.title')}</a></li>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#production">{t('dataHeader.obj_4.title')}</a></li>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#retail">{t('dataHeader.obj_5.title')}</a></li>
    <li className={`${css.item} ${background ? css.item_2 : ''}`}><a href="#contacts">{t('dataHeader.obj_6.title')}</a></li>
    </ul>
  </nav>
  );
};

export default Navigation;
