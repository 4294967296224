import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import Home from "./pages/Home";
// import Contacts from "./pages/Contacts";
// import Products from "./pages/Products";
import Layout from "./Layout/Layout";
import Page404 from "./pages/Page404";

function App() {

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="contacts" element={<Contacts />} /> */}
          {/* <Route path="products" element={<Products />} /> */}

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;