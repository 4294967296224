// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__MM274 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  padding: 0 300px 0 300px;
  background-color: var(--primary-color);
}
@media (max-width: 1920px) {
  .Container_container__MM274 {
    padding: 0 120px 0 120px;
  }
}
@media (max-width: 769px) {
  .Container_container__MM274 {
    padding: 0 16px 0 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EAEE,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,wBAAA;EAIA,sCAAA;AAHF;AAKE;EAXF;IAYI,wBAAA;EAFF;AACF;AAIE;EAfF;IAgBI,sBAAA;EADF;AACF","sourcesContent":[".container {\n\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 1920px;\n  padding: 0 300px 0 300px;\n  // padding: 0 315px 0 315px;\n\n \n  background-color: var(--primary-color);\n\n  @media (max-width: 1920px) {\n    padding: 0 120px 0 120px;\n  }\n\n  @media (max-width: 769px) {\n    padding: 0 16px 0 16px;\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__MM274`
};
export default ___CSS_LOADER_EXPORT___;
