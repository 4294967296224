// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #03989E;
  --main-color_background: rgba(3, 152, 158, 0.5);
  --text-color-white: #FFFFFF;
  --text-color-black: #000000;
  --text-color-green: #03989E;
  --page-color-white: #FFFFFF;
  --page-color-gray: #F5F5F5;
}

body {

  margin: 0;
  padding: 0;

  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none; 
}

img {
  max-width: 100%;
}

input,
button,
textarea {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+CAA+C;EAC/C,2BAA2B;EAC3B,2BAA2B;EAC3B,2BAA2B;EAC3B,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;;EAEE,SAAS;EACT,UAAU;;EAEV,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;EAClC,mCAAmC;AACrC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;;;EAGE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,aAAa;AACf","sourcesContent":[":root {\n  --main-color: #03989E;\n  --main-color_background: rgba(3, 152, 158, 0.5);\n  --text-color-white: #FFFFFF;\n  --text-color-black: #000000;\n  --text-color-green: #03989E;\n  --page-color-white: #FFFFFF;\n  --page-color-gray: #F5F5F5;\n}\n\nbody {\n\n  margin: 0;\n  padding: 0;\n\n  font-family: 'Mulish', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--main-color);\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nul,\nol {\n  list-style: none;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\na:hover,\na:visited,\na:active {\n  color: inherit;\n  text-decoration: none; \n}\n\nimg {\n  max-width: 100%;\n}\n\ninput,\nbutton,\ntextarea {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
