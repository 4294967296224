// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_homeWrapper__ONYa1 {
  margin: 0 auto 0 auto;
  background-color: tomato;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACA,qBAAA;EACA,wBAAA;AACA","sourcesContent":[".homeWrapper {\nmargin: 0 auto 0 auto;\nbackground-color: tomato  ;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeWrapper": `Home_homeWrapper__ONYa1`
};
export default ___CSS_LOADER_EXPORT___;
