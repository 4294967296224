import React, { useState, useEffect, useRef } from "react";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

import css from "./TypicalSlider.module.scss";
import { useTranslation } from "react-i18next";

const TypicalSlider = ({ arrImgData }) => {
  const { t } = useTranslation();

  const itemRef = useRef(null);


  const [move, setMove] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [itemWidth, setItemWidth] = useState(0);


  useEffect(() => {
    if (itemRef.current) {
      const width = itemRef.current.getBoundingClientRect().width;
      console.log('Width:', width);
      setItemWidth(width);
    }
  }, []);

  // Перевстановлення значення move на 0 при зміні arrImgData
  useEffect(() => {
    setMove(0);
  }, [arrImgData]);

  // useEffect(() => {
  //   console.log('START');
  //   // Отримати ширину першого елемента в списку
  //   console.log(css.item);
  //   const firstItem = document.querySelector(`.${css.item}`);
    
  //   console.log(firstItem);
  //   if (firstItem) {
  //     console.log(44444444444);
  //     console.log(firstItem);
  //     setItemWidth(firstItem.offsetWidth);
  //   }
  // }, [arrImgData]);

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleClickMove = (where, event) => {
    event.stopPropagation();
    const maxMove = (arrImgData.arrImg.length - 4) * itemWidth;

    if (where === "go") {
      
      setMove((prevMove) => Math.  min(prevMove + itemWidth, maxMove));
    }

    if (where === "back") {
      setMove((prevMove) => Math.max(prevMove - itemWidth, 0));
    }
  };

  const handleTouchMove = (event) => {
    if (!startX) {
      return;
    }

    const currentX = event.touches[0].clientX;
    const deltaX = startX - currentX;

    setMove((prevMove) => prevMove + deltaX);
    setStartX(currentX);
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = startX - event.clientX;
    setMove((prevMove) => prevMove + deltaX);
    setStartX(event.clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  return (
    <div className={css.sliderWrapper}>
      <div
        className={css.wrapperPositionList}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <ul
          className={`${css.list} noSelect`}
          style={{ transform: `translateX(-${move}px)` }}
        >
          {arrImgData.arrImg.map((el, index) => (
            <li className={css.item} key={index} ref={itemRef}>
              <img src={el} alt={index} />
            </li>
          ))}
        </ul>
        <div className={css.wrapperButton}>
          <button onClick={(event) => handleClickMove("back", event)}>
            <MdKeyboardDoubleArrowLeft className={css.icon} />
          </button>
          <button onClick={(event) => handleClickMove("go", event)}>
            <MdKeyboardDoubleArrowRight className={css.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypicalSlider;
