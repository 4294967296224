// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TypicalSlider_wrapperPositionList__c2QwF {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  touch-action: pan-y;
}
@media (max-width: 769px) {
  .TypicalSlider_wrapperPositionList__c2QwF {
    height: auto;
  }
}

.TypicalSlider_list__s1Oe1 {
  display: flex;
  transition: transform 0.3s ease;
}
.TypicalSlider_list__s1Oe1 .TypicalSlider_item__\\+xIli {
  flex: 0 0 25%;
  text-align: center;
}
.TypicalSlider_list__s1Oe1 .TypicalSlider_item__\\+xIli img {
  display: block;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 769px) {
  .TypicalSlider_list__s1Oe1 .TypicalSlider_item__\\+xIli {
    flex: 0 0 100%;
  }
}

.TypicalSlider_wrapperButton__J-s7Z {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.TypicalSlider_wrapperButton__J-s7Z button {
  border: none;
  background-color: rgb(200, 200, 200);
  text-align: center;
}
.TypicalSlider_wrapperButton__J-s7Z button .TypicalSlider_icon__7dAWo {
  font-size: 40px;
  color: white;
  transition: transform 0.3s ease;
}
.TypicalSlider_wrapperButton__J-s7Z button:hover .TypicalSlider_icon__7dAWo {
  transform: scale(1.2);
}
.TypicalSlider_wrapperButton__J-s7Z button:hover {
  background-color: var(--main-color);
}
@media (max-width: 769px) {
  .TypicalSlider_wrapperButton__J-s7Z {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TypicalSider/TypicalSlider.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;EAGA,mBAAA;AADF;AAGE;EARF;IASI,YAAA;EAAF;AACF;;AAEA;EAEE,aAAA;EACA,+BAAA;AAAF;AAEE;EAEE,aAAA;EACA,kBAAA;AADJ;AAII;EACE,cAAA;EACA,WAAA;EAIA,mBAAA;AALN;AAWE;EACE;IACE,cAAA;EATJ;AACF;;AAaA;EAEE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;AAXF;AAcE;EACE,YAAA;EACA,oCAAA;EACA,kBAAA;AAZJ;AAcI;EACE,eAAA;EACA,YAAA;EACA,+BAAA;AAZN;AAeI;EACE,qBAAA;AAbN;AAgBI;EACE,mCAAA;AAdN;AAkBE;EAhCF;IAiCI,aAAA;EAfF;AACF","sourcesContent":[".wrapperPositionList {\n  position: relative;\n  overflow: hidden; \n  margin: 0 auto;\n  // border: 1px solid tomato;\n  // min-width: 300px;\n  touch-action: pan-y;\n\n  @media (max-width: 769px) {\n    height: auto;\n  }\n}\n.list {\n  // border: 1px solid rgb(57, 151, 107);\n  display: flex;\n  transition: transform 0.3s ease;\n\n  .item {\n    // border: 1px solid rgb(69, 57, 107);\n    flex: 0 0 25%;\n    text-align: center;\n    // height: 100%;\n\n    img {\n      display: block;\n      width: 100%;\n\n      // margin-top: 10px;\n      // height: 250px;\n      object-fit: contain;\n\n\n    }\n  }\n\n  @media (max-width: 769px) {\n    .item {\n      flex: 0 0 100%;\n    }\n  }\n}\n\n.wrapperButton {\n  // border: 1px solid tomato;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: absolute;\n  top: 40%;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 100%;\n  \n\n  button {\n    border: none;\n    background-color: rgb(200, 200, 200);\n    text-align: center;\n\n    .icon {\n      font-size: 40px;\n      color: white;\n      transition: transform 0.3s ease;\n    }\n\n    &:hover .icon {\n      transform: scale(1.2);\n    }\n\n    &:hover {\n      background-color: var(--main-color);\n    }\n  }\n\n  @media (max-width: 769px) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperPositionList": `TypicalSlider_wrapperPositionList__c2QwF`,
	"list": `TypicalSlider_list__s1Oe1`,
	"item": `TypicalSlider_item__+xIli`,
	"wrapperButton": `TypicalSlider_wrapperButton__J-s7Z`,
	"icon": `TypicalSlider_icon__7dAWo`
};
export default ___CSS_LOADER_EXPORT___;
