// Layout.jsx
import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import allData from "./../pages/Home/allData";
import ConnectionStatus from "../components/ConnectionStatus";

const { dataContacts } = allData;

const Layout = () => {
  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <Outlet />
      </main>

      <footer id="contacts">
        <Footer data={dataContacts} />
      </footer>

      <ConnectionStatus/>
    </>
  );
};

export default Layout;
