import React from "react";

const Page404 = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Помилка адреси. Схоже, сторінку не знайдено</p>
    </div>
  );
}

export default Page404;
