import React from "react";
import css from "./SocialLink.module.scss";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const SocialLink = ({ objData, title }) => {
  // console.log(3123123, objData);
  return (
    <div className={css.socialLinkWrapper}>
      <p className={css.title}>{title}</p>

      <div className={css.linkWrapper}>
        <button
          className={css.linkButton}
          onClick={() => window.open(objData.facebook, "_blank", "noopener,noreferrer")}
        >
          <FaFacebookF style={{ color: "white", fontSize: "25px" }}/>
        </button>

        <button
          className={css.linkButton}
          onClick={() => window.open(objData.linkedin, "_blank", "noopener,noreferrer")}
        >
          <FaInstagram style={{ color: "white", fontSize: "25px"}}/>
        </button>

        <button
          className={css.linkButton}
          onClick={() => window.open(objData.instagram, "_blank", "noopener,noreferrer")}
        >
          <FaLinkedinIn style={{ color: "white", fontSize: "25px" }}/>
        </button>
      </div>
    </div>
  );
};

export default SocialLink;
