// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoogleMaps_wrapperTitle__L9Juz {
  background-color: var(--page-color-white);
}

.GoogleMaps_locationText__HC8nO {
  padding: 34px 0 34px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}
.GoogleMaps_locationText__HC8nO span {
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
}

.GoogleMaps_mapWrapper__6ncVw {
  position: relative;
  width: 100%;
  height: 400px;
}
.GoogleMaps_mapWrapper__6ncVw iframe {
  width: 100%;
  height: 100%;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleMaps/GoogleMaps.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF;;AACA;EACE,sBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAQA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AALF;AAOE;EACE,WAAA;EACA,YAAA;EACA,SAAA;AALJ","sourcesContent":[".wrapperTitle {\n  background-color: var(--page-color-white);\n}\n.locationText {\n  padding: 34px 0 34px 0;\n\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 24px;\n\n  span {\n    font-size: 32px;\n    font-weight: 900;\n    line-height: 32px;\n  }\n\n\n\n\n\n}\n\n.mapWrapper {\n  position: relative;\n  width: 100%;\n  height: 400px;\n\n  iframe {\n    width: 100%;\n    height: 100%;\n    border: 0;\n  }\n\n\n\n}\n\n\n\n\n\n@media (max-width: 1360px) {\n}\n\n@media (max-width: 769px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperTitle": `GoogleMaps_wrapperTitle__L9Juz`,
	"locationText": `GoogleMaps_locationText__HC8nO`,
	"mapWrapper": `GoogleMaps_mapWrapper__6ncVw`
};
export default ___CSS_LOADER_EXPORT___;
