// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_titleText__xG3wj {
  font-size: 76px;
  font-weight: 900;
  line-height: 76px;
  color: var(--text-color-black);
  margin-bottom: 60px;
  text-transform: uppercase;
}
@media (max-width: 769px) {
  .Title_titleText__xG3wj {
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 45px;
  }
}

.Title_titleTextColor__\\+PhMJ {
  text-transform: uppercase;
  font-size: 76px;
  font-weight: 900;
  line-height: 76px;
  color: var(--text-color-white);
  margin-bottom: 40px;
}
@media (max-width: 769px) {
  .Title_titleTextColor__\\+PhMJ {
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 45px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;AACF;AAIE;EAXF;IAYI,eAAA;IACA,gBAAA;IACA,iBAAA;IACA,mBAAA;EADF;AACF;;AAIA;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAME;EAXF;IAYI,eAAA;IACA,gBAAA;IACA,iBAAA;IACA,mBAAA;EAHF;AACF","sourcesContent":[".titleText {\n  font-size: 76px;\n  font-weight: 900;\n  line-height: 76px;\n  color: var(--text-color-black);\n  margin-bottom: 60px;\n  text-transform: uppercase;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    font-size: 30px;\n    font-weight: 900;\n    line-height: 30px;\n    margin-bottom: 45px;\n  }\n}\n\n.titleTextColor {\n  text-transform: uppercase;\n  font-size: 76px;\n  font-weight: 900;\n  line-height: 76px;\n  color: var(--text-color-white);\n  margin-bottom: 40px;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    font-size: 30px;\n    font-weight: 900;\n    line-height: 30px;\n    margin-bottom: 45px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": `Title_titleText__xG3wj`,
	"titleTextColor": `Title_titleTextColor__+PhMJ`
};
export default ___CSS_LOADER_EXPORT___;
