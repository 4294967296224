// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectionStatus_connectionStatus__jOB61 {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

.ConnectionStatus_online__mI8nE {
  background-color: var(--main-color);
  padding: 5px;
  border-radius: 5px;
}

.ConnectionStatus_offline__F1XuD {
  background-color: red;
  padding: 5px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ConnectionStatus/ConnectionStatus.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AAAF;;AAKA;EACE,mCAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;AAFF","sourcesContent":["\n.connectionStatus {\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  padding: 10px;\n  border-radius: 5px;\n  color: #fff;\n  font-size: 14px;\n\n\n}\n\n.online {\n  background-color: var(--main-color);\n  padding: 5px;\n  border-radius: 5px;\n}\n\n.offline {\n  background-color: red;\n  padding: 5px;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectionStatus": `ConnectionStatus_connectionStatus__jOB61`,
	"online": `ConnectionStatus_online__mI8nE`,
	"offline": `ConnectionStatus_offline__F1XuD`
};
export default ___CSS_LOADER_EXPORT___;
