import React from "react";

import css from "./Title.module.scss";

const Title = ({title, color}) => {
  // console.log(color);


  return (
    <>
      <h3 className={color === 'white' ? css.titleTextColor : css.titleText}>{title}</h3>
    </>
  );
};

export default Title;
