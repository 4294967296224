// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapsWorld_MapsWorldWrapper__nK2MN {
  background: var(--main-color);
  padding: 80px 0 80px 0;
}
.MapsWorld_MapsWorldWrapper__nK2MN .MapsWorld_countries__hNMDq {
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  color: var(--text-color-white);
}
@media (max-width: 769px) {
  .MapsWorld_MapsWorldWrapper__nK2MN .MapsWorld_countries__hNMDq {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
  }
}
.MapsWorld_MapsWorldWrapper__nK2MN img {
  width: 100%;
}
@media (max-width: 769px) {
  .MapsWorld_MapsWorldWrapper__nK2MN {
    padding: 45px 0 45px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MapsWorld/MapsWorld.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EAEA,sBAAA;AAAF;AAEE;EAEF,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;AADA;AAIA;EARE;IAUF,eAAA;IACA,gBAAA;IACA,iBAAA;EAFE;AACF;AAOE;EACE,WAAA;AALJ;AAaE;EAhCF;IAiCI,sBAAA;EAVF;AACF","sourcesContent":[".MapsWorldWrapper {\n  background: var(--main-color);\n  // border: 1px solid tomato;\n  padding: 80px 0 80px 0;\n\n  .countries {\n\nfont-size: 24px;\nfont-weight: 500;\nline-height: 31px;\ncolor: var(--text-color-white);\n// margin-bottom: 70px;\n\n@media (max-width: 769px) {\n\nfont-size: 16px;\nfont-weight: 500;\nline-height: 21px;\n\n\n}\n  }\n\n  img {\n    width: 100%;\n  }\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    padding: 45px 0 45px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapsWorldWrapper": `MapsWorld_MapsWorldWrapper__nK2MN`,
	"countries": `MapsWorld_countries__hNMDq`
};
export default ___CSS_LOADER_EXPORT___;
