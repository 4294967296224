// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contacts_wrapper__UPwUc {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
}
.Contacts_wrapper__UPwUc .Contacts_iconWrapper__KCqZK {
  margin-right: 8px;
}
.Contacts_wrapper__UPwUc .Contacts_text__gr-FZ {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EAGA,mBAAA;AAHJ","sourcesContent":[".wrapper {\n  color: rgb(255, 255, 255);\n  display: flex;\n  align-items: center;\n\n  .iconWrapper {\n    margin-right: 8px;\n  }\n\n  .text {\n \n    font-size: 14px;\n    font-weight: 700;\n    line-height: 18px;\n\n    \n    white-space: nowrap;\n  }\n  \n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Contacts_wrapper__UPwUc`,
	"iconWrapper": `Contacts_iconWrapper__KCqZK`,
	"text": `Contacts_text__gr-FZ`
};
export default ___CSS_LOADER_EXPORT___;
