// ConnectionStatus.js

import React, { useState, useEffect } from 'react';
import css from './ConnectionStatus.module.scss'; // Підключаємо файли стилів для плашки повідомлень

const ConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine); // Початковий стан - чи є з'єднання з Інтернетом

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus); // Слухаємо подію зміни статусу онлайн
    window.addEventListener('offline', updateOnlineStatus); // Слухаємо подію зміни статусу офлайн

    return () => {
      window.removeEventListener('online', updateOnlineStatus); // Прибираємо обробники подій перед виходом з компоненти
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <div className={css.connectionStatus}>
      {isOnline ? (
        <span className={css.online}>З'єднання активне</span> // Якщо є з'єднання, відображаємо повідомлення "З'єднання активне"
      ) : (
        <span className={css.offline}>Втрачено з'єднання</span> // Якщо з'єднання втрачене, відображаємо повідомлення "Втрачено з'єднання"
      )}
    </div>
  );
};

export default ConnectionStatus;
