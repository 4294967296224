import React from "react";

import css from "./OwnRetail.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import OwnRetailCard from "../OwnRetailCard";
import { useTranslation } from 'react-i18next';


const OwnRetail = ({ data }) => {
  // console.log(data.content);
  const { t, i18n } = useTranslation();

 


  return (
    <div className={css.ownRetailWrapper}>
      <Container>
        <Title title={t(data.title)} />


{
  <ul className={css.ownRetailList}>
    {

  data.content.map((el, index) => (
      <li key={index}>
      <OwnRetailCard objData={el}/>
      </li>
  ))
    }
    </ul>
}
       
      </Container>
    </div>
  );
};

export default OwnRetail;
