import React, { useState } from "react";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import css from "./History.module.scss";
import Container from "../Container";
import Title from "../Title/Title";
import CardHistory from "../CartHistory";
import { useTranslation } from 'react-i18next';

const History = ({ dataHistory }) => {
  const { t, i18n } = useTranslation();

  const [move, setMove] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };
const handleClickMove = (where, howMany) => {
  const wrapperWidth = document.querySelector(`.${css.wrapperPositionList}`).offsetWidth;
  const listWidth = document.querySelector(`.${css.list}`).offsetWidth;
  const maxMove = listWidth - wrapperWidth;

  if (where === 'go') {
    setMove((prevMove) => Math.min(prevMove + howMany, maxMove));
  }

  if (where === 'back') {
    setMove((prevMove) => Math.max(prevMove - howMany, 0));
  }
};

  const handleTouchMove = (event) => {
    if (!startX) {
      return;
    }

    const currentX = event.touches[0].clientX;
    const deltaX = startX - currentX;

    setMove((prevMove) => prevMove + deltaX);
    setStartX(currentX);
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = startX - event.clientX;
    setMove((prevMove) => prevMove + deltaX);
    setStartX(event.clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  return (
    <div className={css.historyWrapper}>
      <Container>
        <Title title={t(dataHistory.title)} />

        <div
          className={css.wrapperPositionList}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <ul
            className={`${css.list} noSelect`}
            style={{ transform: `translateX(-${move}px)` }}
          >
            {dataHistory.history.map((el, index) => (
              <li className={css.item} key={index}>
                <CardHistory objData={el} index={index} />
              </li>
            ))}
          </ul>


          <div className={css.wrapperButton}>
            <button onClick={() => handleClickMove("back", 200)}>
              <MdKeyboardDoubleArrowLeft className={css.icon} />
            </button>

            <button onClick={() => handleClickMove("go", 200)}>
              <MdKeyboardDoubleArrowRight className={css.icon}/>
            </button>
          </div>


        </div>
      </Container>
    </div>
  );
};

export default History;
