import React, { useState, useEffect, useRef } from "react";
import css from "./SimpleSlider_2.module.scss";

const SimpleSlider = ({ dataSlider }) => {
  const totalSlides = dataSlider.length;
  const initialIndex = Math.floor((totalSlides - 1) / 2);

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [itemWidth, setItemWidth] = useState(0);
  const startXRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const updateItemWidth = () => {
      if (listRef.current) {
        const items = listRef.current.children;
        let totalWidth = 0;
        for (let i = 0; i < items.length; i++) {
          totalWidth += items[i].offsetWidth;
        }
        setItemWidth(totalWidth / totalSlides);
      }
    };

    updateItemWidth();

    window.addEventListener("resize", updateItemWidth);
    return () => {
      window.removeEventListener("resize", updateItemWidth);
    };
  }, []);

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!startXRef.current) return;
    const deltaX = e.touches[0].clientX - startXRef.current;
    if (Math.abs(deltaX) < 50) return; // Мінімальна дистанція для розгляду свайпу
    if (deltaX > 0) {
      // Свайп вправо
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
      );
    } else {
      // Свайп вліво
      setCurrentIndex((prevIndex) =>
        prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
      );
    }
    startXRef.current = null;
  };

  return (
    <div className={css.wrapper}>
      <div
        className={css.wrapperSlider}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => (startXRef.current = null)}
      >
        <ul
          ref={listRef}
          className={css.list}
          style={{
            transform: `translateX(-${currentIndex * itemWidth}px)`,
          }}
        >
          {dataSlider.map((slide, i) => (
            <li key={i} className={css.item}>
              <img className={css.image} src={slide.imgUrl} alt={slide.name} />
            </li>
          ))}
        </ul>

        <div className={css.pagination}>
          <div className={css.pagination_dots}>
            {Array.from({ length: totalSlides }).map((_, index) => (
              <div
                key={index}
                className={`${css.dot} ${
                  index === currentIndex ? css.activeDot : ""
                }`}
                onClick={() => setCurrentIndex(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleSlider;
