// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageSwitcher_wrapperLanguage__Z1ArS {
  display: flex;
  align-items: center;
}
.LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_langButton__WCO3- {
  border: none;
  width: 31px;
  height: 31px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_activeLang__dlRi0 {
  background-color: var(--text-color-white);
  color: var(--text-color-green);
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageSwitcher/LanguageSwitcher.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EAGJ,eAAA;EACA,gBAAA;EACA,iBAAA;EAEA,+BACA;AAJA;AAWE;EACE,yCAAA;EACA,8BAAA;AATJ","sourcesContent":[".wrapperLanguage {\n\n  display: flex;\n  align-items: center;\n\n  .langButton {\n    border: none;\n    width: 31px;\n    height: 31px;\n    background-color: transparent;\n\n\nfont-size: 14px;\nfont-weight: 900;\nline-height: 18px;\n\ncolor: \nrgba(255, 255, 255, 0.5);\n\n\n\n\n\n  }\n  .activeLang {\n    background-color: var(--text-color-white);\n    color: var(--text-color-green);\n  }\n\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperLanguage": `LanguageSwitcher_wrapperLanguage__Z1ArS`,
	"langButton": `LanguageSwitcher_langButton__WCO3-`,
	"activeLang": `LanguageSwitcher_activeLang__dlRi0`
};
export default ___CSS_LOADER_EXPORT___;
