// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_wrapperNavLine__q5Rb2 {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-right: 12px;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 50px;
  margin-right: 50px;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ .Navigation_item__PQCMi {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  white-space: nowrap;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ .Navigation_item__PQCMi:not(:last-child) {
  margin-right: 12px;
}
.Navigation_wrapperNavColumn__cCfaS {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
}
.Navigation_wrapperNavColumn__cCfaS .Navigation_list__hY2hZ {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Navigation_wrapperNavColumn__cCfaS .Navigation_list__hY2hZ .Navigation_item__PQCMi {
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  white-space: nowrap;
}
.Navigation_wrapperNavColumn__cCfaS .Navigation_list__hY2hZ .Navigation_item_2__YQPco {
  padding: 10px 20px 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 769px) {
  .Navigation_wrapperNavColumn__cCfaS {
    align-items: start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EAEA,kBAAA;EACA,kBAAA;AAAF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AAAF;AAEE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EAGD,mBAAA;AAHH;AAME;EACE,kBAAA;AAJJ;AAmBA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAjBF;AAmBC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAjBF;AAqBE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EAGD,mBAAA;AAtBH;AAyBE;EACE,4BAAA;EACA,oCAAA;AAvBJ;AAoCE;EAvCF;IAwCI,kBAAA;EAjCF;AACF","sourcesContent":[".wrapperNavLine {\n  color: rgb(255, 255, 255);\n  display: flex;\n  align-items: center;\n  // justify-content: space-around;\n  margin-right: auto;\n  margin-right: 12px;\n\n .list {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  margin-left: 50px;\n  margin-right: 50px;\n\n  .item {\n \n    font-size: 14px;\n    font-weight: 700;\n    line-height: 18px;\n    text-transform: uppercase;\n\n    \n   white-space: nowrap;\n  }\n\n  .item:not(:last-child) {\n    margin-right: 12px;\n}\n }\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n  }\n}\n\n\n\n.wrapperNavColumn {\n  color: rgb(255, 255, 255);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-right: auto;\n\n .list {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  // align-items: center;\n\n  .item {\n\n    font-size: 20px;\n    font-weight: 900;\n    line-height: 25px;\n\n    \n   white-space: nowrap;\n  }\n\n  .item_2 {\n    padding: 10px 20px 10px 20px;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n\n//   .item:not(:last-child) {\n//     margin-right: 24px;\n// }\n }\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 769px) {\n    align-items: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperNavLine": `Navigation_wrapperNavLine__q5Rb2`,
	"list": `Navigation_list__hY2hZ`,
	"item": `Navigation_item__PQCMi`,
	"wrapperNavColumn": `Navigation_wrapperNavColumn__cCfaS`,
	"item_2": `Navigation_item_2__YQPco`
};
export default ___CSS_LOADER_EXPORT___;
