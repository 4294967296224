import React, { useEffect, useState } from "react";

import css from "./Product.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import TypicalSlider from "../TypicalSider/TypicalSlider";

const Product = ({ data }) => {
  // console.log("data", data.content);
  const { t, i18n } = useTranslation();

  const [activeButton, setActiveButton] = useState(data.content[0].nameProduct);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

// useEffect(() => {
//   console.log(activeButton);
// }, [activeButton])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log(123, windowWidth);
  }, [windowWidth]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  // Фільтрація для отримання об'єкта даних для активної кнопки
  const activeContent = data.content.find(
    (el) => el.nameProduct === activeButton
  );

    // console.log('activeContent', activeContent);
  

  return (
    <div className={css.productWrapper}>
      <Container>
        <Title title={t(data.title)} />

        {data.descriptionTitle ? (
          <p className={css.descriptionTitle}>{t(data.descriptionTitle)}</p>
        ) : (
          ""
        )}

        <ul className={css.listButton}>
          {data.content.map((el, index) => (
            <li key={index}>
              <button
                className={
                  el.nameProduct === activeButton ? css.activeButton : ""
                }
                onClick={() => handleButtonClick(el.nameProduct)}
              >
                {t(el.nameProduct)}
              </button>
            </li>
          ))}
        </ul>

        {activeContent && (
          <div className={css.contentWrapper}>
            <p className={css.description}>{t(activeContent.description)}</p>

            <ul className={css.listProducts}>
              {activeContent.arrProducts.map((el, index) => {
                const rowIndex = Math.floor(
                  index / (windowWidth > 740 ? 4 : 2)
                ); // Визначаємо номер рядка
                const isEvenRow = rowIndex % 2 === 0; // Визначаємо, чи є рядок парним
                const columnIndex = index % 2; // Визначаємо номер стовпця

                // Застосовуємо стиль `decor` для десктопних пристроїв у шахматному порядку
                const shouldApplyDecor = isEvenRow
                  ? columnIndex % 2 !== 0
                  : columnIndex % 2 === 0;

                // Застосовуємо стиль `decorMobile` для мобільних пристроїв у шахматному порядку
                const shouldApplyDecorMobile =
                  rowIndex % 2 === 0 && columnIndex === 0;

                return (
                  <li
                    className={`${css.itemProducts} ${
                      shouldApplyDecor ? css.decor : ""
                    } ${shouldApplyDecorMobile ? css.decorMobile : ""}`}
                    key={index}
                  >
                    {t(el)}
                  </li>
                );
              })}
            </ul>





            <TypicalSlider arrImgData = {activeContent}/>








            {/* <ul className={css.listImg}>
              {activeContent.arrImg.map((el, index) => (
                <li key={index}>
                  <img src={el} alt={"content" + index} />
                </li>
              ))}
            </ul> */}



          </div>
        )}
      </Container>
    </div>
  );
};

export default Product;
