const dataSlider_1 = [
  {
    id: 1,
    name: "slide_1",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_1.png",
  },
  {
    id: 2,
    name: "slide_2",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_2.png",
  },
  {
    id: 3,
    name: "slide_3",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_3.png",
  },
  {
    id: 4,
    name: "slide_1",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_4.png",
  },
  {
    id: 5,
    name: "slide_2",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_5.png",
  },
  {
    id: 6,
    name: "slide_3",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_6.png",
  },
  {
    id: 7,
    name: "slide_3",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_1/slide_7.png",
  },
];

const dataAboutUs = {
  id: 1,
  // title: "ПРО КОМПАНІЮ",
  title: "dataAboutUs.title",
  text: [
    "dataAboutUs.text.el1",
    "dataAboutUs.text.el2",
  ],
};

const dataHistory = {
  title: "dataHistory.title",
  history: [
    {
      id: 1,
      year: "2007",
      title: "dataHistory.history.obj_1.title",
      description: "dataHistory.history.obj_1.description",
    },
    {
      id: 2,
      year: "2012",
      title: "dataHistory.history.obj_2.title",
      description: "dataHistory.history.obj_2.description",
    },
    {
      id: 3,
      year: "2015",
      title: "dataHistory.history.obj_3.title",
      description: "dataHistory.history.obj_3.description",
    },
    {
      id: 4,
      year: "2018",
      title: "dataHistory.history.obj_4.title",
      description: "dataHistory.history.obj_4.description",
    },
    {
      id: 5,
      year: "2019",
      title: "dataHistory.history.obj_5.title",
      description: "dataHistory.history.obj_5.description",
    },
    {
      id: 6,
      year: "2020",
      title: "dataHistory.history.obj_6.title",
      description: "dataHistory.history.obj_6.description",
    },
    {
      id: 7,
      year: "2021",
      title: "dataHistory.history.obj_7.title",
      description: "dataHistory.history.obj_7.description",
    },
    {
      id: 8,
      year: "2023",
      title: "dataHistory.history.obj_8.title",
      description: "dataHistory.history.obj_8.description",
    },

  ],
}


const dataProduct = {
  title: "dataProduct.title",
  content: [
    {
      id: 1,
      nameProduct: "dataProduct.content.obj_1.nameProduct",
      description: "dataProduct.content.obj_1.description",
      arrProducts: [
        "dataProduct.content.obj_1.arrProducts.el_1",
        "dataProduct.content.obj_1.arrProducts.el_2",
        "dataProduct.content.obj_1.arrProducts.el_3",
        "dataProduct.content.obj_1.arrProducts.el_4",
        "dataProduct.content.obj_1.arrProducts.el_5",
        "dataProduct.content.obj_1.arrProducts.el_6",
        "dataProduct.content.obj_1.arrProducts.el_7",
        "dataProduct.content.obj_1.arrProducts.el_8",
        "dataProduct.content.obj_1.arrProducts.el_9",
        "dataProduct.content.obj_1.arrProducts.el_10",
        "dataProduct.content.obj_1.arrProducts.el_11",
        "dataProduct.content.obj_1.arrProducts.el_12",
        "dataProduct.content.obj_1.arrProducts.el_13",
        "dataProduct.content.obj_1.arrProducts.el_14",
        "dataProduct.content.obj_1.arrProducts.el_15",
        "dataProduct.content.obj_1.arrProducts.el_16",
      ],
      arrImg: [
        "/media/images/product/product_1.jpg",
        "/media/images/product/product_2.jpg",
        "/media/images/product/product_3.jpg",
        "/media/images/product/product_4.jpg",
        "/media/images/product/product_5.jpg",
        "/media/images/product/product_6.jpg",
        "/media/images/product/product_7.jpg",
        "/media/images/product/product_8.jpg",
        "/media/images/product/product_9.jpg",
        "/media/images/product/product_10.jpg",
        "/media/images/product/product_11.jpg",
        "/media/images/product/product_12.jpg",
        "/media/images/product/product_13.jpg",
        "/media/images/product/product_14.jpg",
      ],
    },

    {
      id: 2,
      nameProduct: "dataProduct.content.obj_2.nameProduct",
      description: "dataProduct.content.obj_2.description",
      arrProducts: [
        "dataProduct.content.obj_2.arrProducts.el_1",
        "dataProduct.content.obj_2.arrProducts.el_2",
        "dataProduct.content.obj_2.arrProducts.el_3",
        "dataProduct.content.obj_2.arrProducts.el_4",
        "dataProduct.content.obj_2.arrProducts.el_5",
        "dataProduct.content.obj_2.arrProducts.el_6",
        "dataProduct.content.obj_2.arrProducts.el_7",
      ],
      arrImg: [
        "/media/images/product/product_15.jpg",
        "/media/images/product/product_16.jpg",
        "/media/images/product/product_17.jpg",
        "/media/images/product/product_18.jpg",
        "/media/images/product/product_19.jpg",
        "/media/images/product/product_20.jpg",
        // "/media/images/product/product_21.jpg",
        "/media/images/product/product_22.jpg",
        "/media/images/product/product_23.jpg",
      ],
    },

    {
      id: 3,
      nameProduct: "dataProduct.content.obj_3.nameProduct",
      description: "dataProduct.content.obj_3.description",
      arrProducts: [
        "dataProduct.content.obj_3.arrProducts.el_1",
        "dataProduct.content.obj_3.arrProducts.el_2",
        "dataProduct.content.obj_3.arrProducts.el_3",
        "dataProduct.content.obj_3.arrProducts.el_4",
        "dataProduct.content.obj_3.arrProducts.el_5",
        "dataProduct.content.obj_3.arrProducts.el_6",
  
      ],
      arrImg: [
        "/media/images/product/product_21.jpg",
        "/media/images/product/product_24.jpg",
        "/media/images/product/product_25.jpg",
        "/media/images/product/product_26.jpg",
        "/media/images/product/product_27.jpg",
        "/media/images/product/product_28.jpg",
        "/media/images/product/product_29.jpg",
        "/media/images/product/product_30.jpg",
      ],
    },

    {
      id: 4,
      nameProduct: "dataProduct.content.obj_4.nameProduct",
      description: "dataProduct.content.obj_4.description",
      arrProducts: [
        "dataProduct.content.obj_4.arrProducts.el_1",
        "dataProduct.content.obj_4.arrProducts.el_2",
        "dataProduct.content.obj_4.arrProducts.el_3",
        "dataProduct.content.obj_4.arrProducts.el_4",
        "dataProduct.content.obj_4.arrProducts.el_5",
        "dataProduct.content.obj_4.arrProducts.el_6",
  
      ],
      arrImg: [
        "/media/images/product/product_31.jpg",
        "/media/images/product/product_32.jpg",
        "/media/images/product/product_33.jpg",
        "/media/images/product/product_34.jpg",
        "/media/images/product/product_35.jpg",
        "/media/images/product/product_36.jpg",
      ],
    },
  ],
};

const dataWorldMap = {
  title: "dataWorldMap.title",
  description:
    "dataWorldMap.description",
  // imgUrl: process.env.PUBLIC_URL + "/media/images/decor/mapWorld.png",
  imgUrl: process.env.PUBLIC_URL + "/media/images/decor/map.png",
};

const dataProduction = {
  title: "dataProduction.title",
  descriptionTitle:
  "dataProduction.descriptionTitle",
  content: [
    {
      id: 1,
      nameProduct: "dataProduction.content.obj_1.nameProduct",
      description:
        "dataProduction.content.obj_1.description",
      arrProducts: [
        "dataProduction.content.obj_1.arrProducts.el_1",
        "dataProduction.content.obj_1.arrProducts.el_2",
        "dataProduction.content.obj_1.arrProducts.el_3",
        "dataProduction.content.obj_1.arrProducts.el_4",
        "dataProduction.content.obj_1.arrProducts.el_5",
        "dataProduction.content.obj_1.arrProducts.el_6",
        "dataProduction.content.obj_1.arrProducts.el_7",
        "dataProduction.content.obj_1.arrProducts.el_8",
        "dataProduction.content.obj_1.arrProducts.el_9",
        "dataProduction.content.obj_1.arrProducts.el_10",
        "dataProduction.content.obj_1.arrProducts.el_11",
      ],
      arrImg: [
        "/media/images/production/production_1_1.png",
        "/media/images/production/production_1_2.png",
        "/media/images/production/production_1_3.png",
        "/media/images/production/production_1_4.png",
        "/media/images/production/production_1_5.png",
        "/media/images/production/production_1_6.png",
        "/media/images/production/production_1_7.png",
        "/media/images/production/production_1_8.png",
        "/media/images/production/production_1_9.png",
        "/media/images/production/production_1_10.png",
        "/media/images/production/production_1_11.png",
        "/media/images/production/production_1_12.png",
        "/media/images/production/production_1_13.png",
        "/media/images/production/production_1_14.png",
        "/media/images/production/production_1_15.png",
        "/media/images/production/production_1_16.png",
        "/media/images/production/production_1_17.png",
        "/media/images/production/production_1_18.png",
        "/media/images/production/production_1_19.png",
        "/media/images/production/production_1_20.png",
        "/media/images/production/production_1_21.png",
        "/media/images/production/production_1_22.png",
        // "/media/images/production/production_1_23.png",
        "/media/images/production/production_1_24.png",
        "/media/images/production/production_1_25.png",
      ],
    },

    {
      id: 2,
      nameProduct: "dataProduction.content.obj_2.nameProduct",
      description:
      "dataProduction.content.obj_2.description",
      arrProducts: [
        "dataProduction.content.obj_2.arrProducts.el_1",
        "dataProduction.content.obj_2.arrProducts.el_2",
      
      
      ],
      arrImg: [
        "/media/images/production/production_2_1.png",
        "/media/images/production/production_2_2.png",
        "/media/images/production/production_2_3.png",
        "/media/images/production/production_2_4.png",
      ],
    },

    {
      id: 3,
      nameProduct: "dataProduction.content.obj_3.nameProduct",
      description:
      "dataProduction.content.obj_3.description",
      arrProducts: [
        "dataProduction.content.obj_3.arrProducts.el_1",
        "dataProduction.content.obj_3.arrProducts.el_2",
        "dataProduction.content.obj_3.arrProducts.el_3",
        "dataProduction.content.obj_3.arrProducts.el_4",
        "dataProduction.content.obj_3.arrProducts.el_5",
        "dataProduction.content.obj_3.arrProducts.el_6",
        "dataProduction.content.obj_3.arrProducts.el_7",
        "dataProduction.content.obj_3.arrProducts.el_8",
        "dataProduction.content.obj_3.arrProducts.el_9",
        "dataProduction.content.obj_3.arrProducts.el_10",
        "dataProduction.content.obj_3.arrProducts.el_11",
        "dataProduction.content.obj_3.arrProducts.el_12",
        "dataProduction.content.obj_3.arrProducts.el_13",
      ],
      arrImg: [
        "/media/images/production/production_3_1.png",
        "/media/images/production/production_3_2.png",
        "/media/images/production/production_3_3.png",
        "/media/images/production/production_3_4.png",
        "/media/images/production/production_3_5.png",
        "/media/images/production/production_3_6.png",
        "/media/images/production/production_3_7.png",
        "/media/images/production/production_3_8.png",
        "/media/images/production/production_3_9.png",
        "/media/images/production/production_3_10.png",
        "/media/images/production/production_3_11.png",
        "/media/images/production/production_3_12.png",
        "/media/images/production/production_3_13.png",
        "/media/images/production/production_3_14.png",
        "/media/images/production/production_3_15.png",
        "/media/images/production/production_3_16.png",
  
      ],
    },

    {
      id: 4,
      nameProduct: "dataProduction.content.obj_4.nameProduct",
      description:
      "dataProduction.content.obj_4.description",
      arrProducts: [
        "dataProduction.content.obj_4.arrProducts.el_1",
        "dataProduction.content.obj_4.arrProducts.el_2",
        "dataProduction.content.obj_4.arrProducts.el_3",
        "dataProduction.content.obj_4.arrProducts.el_4",
        "dataProduction.content.obj_4.arrProducts.el_5",
     
      ],
      arrImg: [
        "/media/images/production/production_4_1.png",
        "/media/images/production/production_4_2.png",
        "/media/images/production/production_4_3.png",
        "/media/images/production/production_4_4.png",
        "/media/images/production/production_4_5.png",
        "/media/images/production/production_4_6.png",

      ],
    },

    {
      id: 5,
      nameProduct: "dataProduction.content.obj_5.nameProduct",
      description:
      "dataProduction.content.obj_5.description",
      arrProducts: [
        "dataProduction.content.obj_5.arrProducts.el_1",
        "dataProduction.content.obj_5.arrProducts.el_2",
        "dataProduction.content.obj_5.arrProducts.el_3",
        "dataProduction.content.obj_5.arrProducts.el_4",
 

    
      ],
      arrImg: [
        "/media/images/production/production_5_1.png",
        "/media/images/production/production_5_2.png",
        "/media/images/production/production_5_3.png",
 
      ],
    },
   
  ],
};

const dataSlider_2 = [
  {
    id: 1,
    name: "slideProduction_1.png",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_2/slideProduction_1.png",
  },
  {
    id: 1,
    name: "slideProduction_2.png",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_2/slideProduction_2.png",
  },
  {
    id: 1,
    name: "slideProduction_3.png",
    imgUrl: process.env.PUBLIC_URL + "/media/images/slides/slider_2/slideProduction_3.png",
  },
];

const dataOwnRetail = {
  title: 'dataOwnRetail.title',
  content: [
    {
      id: 1,
      imgUrl: process.env.PUBLIC_URL + "/media/images/ownRetail/ownRetail_1.png",
      title: 'dataOwnRetail.content.obj_1.title',
      description: 'dataOwnRetail.content.obj_1.description',
      siteURL: 'https://kramar-shop.com/',
    },

    {
      id: 2,
      imgUrl: process.env.PUBLIC_URL + "/media/images/ownRetail/ownRetail_4.png",
      title: 'dataOwnRetail.content.obj_2.title',
      description: 'dataOwnRetail.content.obj_2.description',
      siteURL: 'https://kramline.com/',
    },
  ]
}

const dataOffice = {
  title: 'dataOffice.title',
  description: 'dataOffice.description',
  arrServices: [
    {
      id:1,
      title: 'dataOffice.arrServices.obj_1.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_1.png",
    },
    {
      id:2,
      title: 'dataOffice.arrServices.obj_2.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_2.png",
    },
    {
      id:3,
      title: 'dataOffice.arrServices.obj_3.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_3.png",
    },
    {
      id:4,
      title: 'dataOffice.arrServices.obj_4.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_4.png",
    },
    {
      id:5,
      title: 'dataOffice.arrServices.obj_5.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_5.png",
    },
    {
      id:6,
      title: 'dataOffice.arrServices.obj_6.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_6.png",
    },
    {
      id:7,
      title: 'dataOffice.arrServices.obj_7.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_7.png",
    },
    {
      id:7,
      title: 'dataOffice.arrServices.obj_8.title',
      imgUrl: process.env.PUBLIC_URL + "/media/images/office/Layer_8.png",
    },
  ],

  titleButton: 'dataOffice.titleButton'
}

const dataContacts = 
  {
    title_1: 'dataContacts.title_1',
    title_2: 'dataContacts.title_2',
    title_3: 'dataContacts.title_3',
    title_4: 'dataContacts.title_4',
    location: {
      text: "dataContacts.location.text",
      

      pathAPI:
   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100793.43222889653!2d23.93269862651809!3d49.83777530278049!2m3!1f0!2f0!3f0!3m2!1i1024!2i769!4f13.1!3m3!1m2!1s0x473ae74dea8fa86f%3A0x251e822011b4247f!2z0LLRg9C70LjRhtGPINCU0LDQvdC40LvQsCDQkNC_0L7RgdGC0L7Qu9CwLCAxNiwg0JvRjNCy0ZbQsiwg0JvRjNCy0ZbQstGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCA3OTA0MA!5e0!3m2!1suk!2sua!4v1704924016494!5m2!1suk!2sua",
    },

    email: "office@kramar-ltd.com.ua",
    phone: ["+38 (097) 674 74 24", '+38 (067) 674 74 24'],

    link: {
      facebook: 'https://www.facebook.com/kramarltd/',
      linkedin: 'https://www.instagram.com/kramar.ltd/',
      instagram: 'https://www.linkedin.com/company/llc-td-kramar-ltd/',
    }
  }


const allData = {
  dataSlider_1,
  dataAboutUs,
  dataHistory,
  dataProduct,
  dataWorldMap,
  dataProduction,
  dataSlider_2,
  dataOwnRetail,
  dataOffice,
  dataContacts,
};

export default allData;
