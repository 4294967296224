import React from "react";
import css from "./GoogleMaps.module.scss";
import Container from "../Container";
import { useTranslation } from 'react-i18next';

const GoogleMaps = ({ data }) => {
  // console.log(data.location);
  const { t, i18n } = useTranslation();
  return (
    <>

    <div className={css.wrapperTitle}>

      <Container>
        <p className={css.locationText}>
          <span>{t(data.title_1)}</span> &nbsp;
          {t(data.location.text)}
        </p>
      </Container>
    </div>

      <div className={css.mapWrapper}>
        <iframe
          src={data.location.pathAPI}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default GoogleMaps;
